import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ policy around the use of enryption algorithms.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`See Purpose.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`The purpose of this policy is to ensure all servers deployed at
Data Migrators are configured according to the Data Migrators
security policies. Servers deployed at Data Migrators shall be audited
at least annually and as prescribed by applicable regulatory compliance.`}</p>
    <p>{`Audits may be conducted to:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Ensure integrity, confidentiality and availability of information
and resources`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ensure conformance to Data Migrators security policies`}</p>
      </li>
    </ul>
    <h2>{`Scope`}</h2>
    <p>{`This policy covers all servers owned or operated by Data Migrators.
This policy also covers any server present on Data Migrators premises,
but which may not be owned or operated by Data Migrators.`}</p>
    <h2>{`Policy`}</h2>
    <p>{`Data Migrators provides its consent to allow selected third party organisations
to access its servers to the extent necessary to
allow each third party to perform scheduled and ad hoc audits of
all servers at Data Migrators.`}</p>
    <h3>{`Specific Concerns`}</h3>
    <p>{`Servers in use for Data Migrators support critical business functions
and store company sensitive information. Improper configuration of
servers could lead to the loss of confidentiality, availability or
integrity of these systems.`}</p>
    <h3>{`Guidelines`}</h3>
    <p>{`Approved and standard configuration templates shall be used when
deploying server systems to include:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`All system logs shall be sent to a central log review system`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`All Sudo / Administrator actions must be logged`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use a central patch deployment system`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Host security agent such as antivirus shall be installed and updated`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Network scan to verify only required network ports and network
shares are in use`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Verify administrative group membership`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Conduct baselines when systems are deployed and upon significant
system changes`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Changes to configuration template shall be coordinated with approval
of change control board`}</p>
      </li>
    </ul>
    <h3>{`Responsibility`}</h3>
    <p>{`A selected third party organisation shall conduct audits of all servers
owned or operated by Data Migrators. Server and application owners are
encouraged to also perform this work as needed.`}</p>
    <h3>{`Relevant Findings`}</h3>
    <p>{`All relevant findings discovered as a result of the audit shall be
listed in the Data Migrators tracking system to ensure prompt
resolution or appropriate mitigating controls.`}</p>
    <h3>{`Ownership of Audit Report.`}</h3>
    <p>{`All results and findings generated by the selected third party must
be provided to appropriate Data Migrators management
within one week of project completion. This report will become the
property of Data Migrators and be considered company confidential.`}</p>
    <h2>{`Policy Compliance`}</h2>
    <h2>{`Compliance Measurement`}</h2>
    <p>{`The selected third party shall never use access required to
perform server audits for any other purpose`}</p>
    <p>{`The Infosec Team will verify compliance to this policy through various
methods, including but not limited to, business tool reports, internal
and external audits, and feedback to the policy owner.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`Any exception to the policy must be approved by the Infosec Team in
advance.`}</p>
    <h2>{`Non-Compliance`}</h2>
    <p>{`An employee found to have violated this policy may be subject to
disciplinary action, up to and including termination of employment.`}</p>
    <h2>{`Related Documents`}</h2>
    <p>{`None.`}</p>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`None.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      